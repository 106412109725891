<template>
  <el-dialog
    :title="form.id ? '修改卡片类型' : '新增卡片类型'"
    :visible.sync="dialogFormVisible"
    width="406px"
  >
    <el-form :model="form" :rules="rules" label-width="80px" ref="form">
      <el-form-item label="类型名称" prop="name">
        <el-input
          v-model="form.name"
          maxlength="10"
          placeholder="填写10个字以内的名称"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="卡面图片" prop="coverImage">
        <el-upload
          class="avatar-uploader"
          :action="uploadUrl"
          required
          accept="image/*"
          :show-file-list="false"
          :before-upload="beforeUpload"
        >
          <img v-if="form.coverImage" :src="form.coverImage" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <!-- <span class="tips">若未上传，则显示默认图片</span> -->
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import upload from "@/utils/upload";
import { mapGetters } from "vuex";
import { addCardConfig, updateCardConfig } from "@/http/awardCard";
export default {
  data() {
    return {
      form: {},
      dialogFormVisible: false,
      rules: {
        name: [{ required: true, message: "请输入类型名称", trigger: "blur" }],
        coverImage: [{ required: true, message: "请上传卡面图片", trigger: "blur" }],
      },
      uploadUrl: "",
      ossCli: null,
    };
  },
  props: {
    row: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    this.ossCli = new upload(this.userInfo.tnId);
    this.form = JSON.parse(JSON.stringify(this.row));
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {
    row() {
      this.form = JSON.parse(JSON.stringify(this.row));
    },
  },
  methods: {
    beforeUpload(file) {
      this.ossCli
        .querySchoolCapacity()
        .then(() => {
          if (this.ossCli.capacity - this.ossCli.usedCapacity > file.size) {
            this.ossCli.upload2oss(file, file.name).then((res) => {
              this.form.coverImage = res;
              this.$forceUpdate();
            });
          } else {
            this.$messsage.error("学校流量不足，请充值");
          }
        })
        .catch((e) => {
          console.log("ee", e);
          this.$messsage.error(e)
        });
      return false;
    },
    show() {
      this.dialogFormVisible = true;
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.schoolId = this.userInfo.tnId;
          if (this.form.id) {
            updateCardConfig(this.form).then((res) => {
              if (res.retCode == 1) {
                this.$message.success("修改成功");
                this.dialogFormVisible = false;
                this.form = {};
                this.$emit("close");
              } else {
                this.$message.error(res.retMsg);
              }
            });
          } else {
            addCardConfig(this.form).then((res) => {
              if (res.retCode == 1) {
                this.$message.success("保存成功");
                this.dialogFormVisible = false;
                this.form = {};
                this.$emit("close");
              } else {
                this.$message.error(res.retMsg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang='scss'>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #00c9d9 !important;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 112px;
  height: 63px;
  line-height: 63px;
  text-align: center;
}
.avatar {
  width: 112px;
  height: 63px;
  display: block;
}
</style>
<style scoped>
.tips {
  color: #999999;
}
</style>