<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import X from "xlsx";
import { saveAs } from "file-saver";

export default {
  name: "export",
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    header: {
      type: Array,
      default() {
        return [];
      },
    },
    name: {
      type: String,
      default() {
        return "教师卡片模板";
      },
    },
  },
  methods: {
    export() {
      /* convert state to workbook */
      let ws;
      if (this.header.length > 0) {
        ws = X.utils.json_to_sheet(this.tableData, {
          header: this.header, //通过设置field可以把这个field排在前头，后续截取表格的时候，把尾部不需要的剔除
        });
      } else {
        ws = X.utils.aoa_to_sheet(this.tableData);
      }

      const wb = X.utils.book_new();
      X.utils.book_append_sheet(wb, ws, "SheetJS");

      /* generate X file */
      const wbout = X.write(wb, { type: "binary", bookType: "xlsx" });
      /* send to client */
      saveAs(
        new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
        this.name + ".xlsx"
      );
    },
    s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
  },
};
</script>
