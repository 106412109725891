//封装上传至oos服务器
// import store from "@/store"
import {
	createOSSPolicy,
	// getStamp,
	querySchoolCapacity,
	// saveFileInfo
} from "@/http/upload"
// import CryptoJS from 'crypto-js'
// import MD5 from 'js-md5'
import axios from 'axios'

// function decrypt(encryptedBase64Str, CRYPTOJSKEY) {
//     CRYPTOJSKEY = MD5(CRYPTOJSKEY).toUpperCase()
//     console.log('CRYPTOJSKEY :>> ', CRYPTOJSKEY)
//     var vals = encryptedBase64Str.replace(/\\-/g, '+').replace(/_/g, '/')
//     var options = {
//         mode: CryptoJS.mode.ECB,
//         padding: CryptoJS.pad.Pkcs7
//     }
//     var key = CryptoJS.enc.Utf8.parse(CRYPTOJSKEY)
//     var decryptedData = CryptoJS.AES.decrypt(vals, key, options)
//     console.log(decryptedData, '1111')
//     var decryptedStr = CryptoJS.enc.Utf8.stringify(decryptedData)
//     return decryptedStr
// }

// function bucket() {
//     let bucket = 'test91chenzhi'
//     // if (location.hostname.indexOf('test.91chenzhi.cn') != -1) {
//     //   bucket = 'test91chenzhi'
//     // } else if (location.hostname.indexOf('dingding.91chenzhi.cn') != -1) {
//     //   bucket = 'ding91chenzhi'
//     // } else if (location.hostname.indexOf('testxuejiajia.91chenzhi.cn') != -1) {
//     //   bucket = 'test91chenzhi'
//     // } else if (location.hostname.indexOf('xuejiajia.91chenzhi.cn') != -1) {
//     //   bucket = '91chhenzhi'
//     // } else if (location.hostname.indexOf('91chenzhi.cn') != -1) {
//     //   bucket = '91chhenzhi'
//     // }
//     return bucket
// }

class upload {
	constructor(arg) {
		this.params = {
			schoolId: arg
		}
		//总量
		this.capacity = 0
		//已使用
		this.usedCapacity = 0
		//获取文件上传授权
		this.getPolicy()
	}

	async getPolicy() {
		let response = await createOSSPolicy({ ...this.params, showLoading: false })
		this.host = response.data.host;
		this.accessKey = response.data.accessId;
		this.policy = response.data.policy;
		this.signature = response.data.signature;
		this.timestamp = response.data.timestamp * 1000
	}

	//查询用户内容库使用情况
	querySchoolCapacity() {
		return new Promise((resolve, reject) => {
			querySchoolCapacity({ ...this.params, showLoading: false }).then(res => {
				if (res.retCode != 0) {
					this.capacity = res.data.capacity
					this.usedCapacity = res.data.usedCapacity
					resolve()
				} else {
					reject(res.retMsg)
				}


			}).catch(e => {
				reject(e)
			})
		})
	}

	//上传至oss
	async upload2oss(file, uploadFileName) {
		// 预留10s请求接口时间
		if ((new Date().getTime()) >= (this.timestamp - (10 * 1000))) {
			await this.getPolicy()
		}
		let param = new FormData(); //创建form对象
		param.append('key', 'chenzhi/' + this.params.schoolId + '/' + uploadFileName);
		param.append('policy', this.policy);
		param.append('OSSAccessKeyId', this.accessKey);
		param.append('signature', this.signature);
		param.append('file', file, 'chenzhi/' + this.params.schoolId + '/' + uploadFileName);
		return new Promise((resolve, reject) => {
			axios.post(this.host, param, {
				headers: { 'Content-Type': 'multipart/form-data' }
			}).then(() => {
				resolve(this.host + '/chenzhi/' + this.params.schoolId + '/' + uploadFileName)
			}).catch(e => {
				reject(e)
			})
		})
		// uni.uploadFile({
		// 	url: this.host,
		// 	filePath: file,
		// 	fileType: fileType == 2 ? 'audio' : 'image',
		// 	name: 'file',
		// 	formData: {
		// 		key: 'chenzhi/' + store.getters.currentUser.schoolId + '/' + uploadFileName,
		// 		policy: this.policy,
		// 		OSSAccessKeyId: this.accessKey,
		// 		signature: this.signature
		// 	},
		// 	success: (res) => {
		// 		resolve(this.host + '/chenzhi/' + store.getters.currentUser.schoolId + '/' + uploadFileName,)
		// 	},
		// 	fail: err => {
		// 		reject(err)
		// 	}
		// })
	}

	// saveFileInfo() {
	//     saveFileInfo().then(res => {

	//     })
	// }
}

export default upload;
