import request from './request'

//获取授权
export const createOSSPolicy = (data) => {
  return request({
    url: '/banpai/api/repo/createOSSPolicy',
    method: 'get',
    params: data,
  });
}

//获取加密后的accessKey
export const getStamp = (data) => {
  return request({
    url: '/banpai/api/repo/getStamp',
    method: 'get',
    params: data,
  });
}
//查询用户内容库使用情况
export const querySchoolCapacity = (data) => {
  return request({
    url: '/banpai/api/repo/querySchoolCapacity',
    method: 'get',
    params: data,
  });
}

//保存文件信息
export const saveFileInfo = (data) => {
  return request({
    url: '/banpai/api/repo/saveFileInfo',
    method: 'post',
    params: data,
  });
}

