<template>
  <award-card>
    <template #buttons>
      <el-button
        type="primary"
        :size="$publicConfig.buttonSize"
        icon="el-icon-plus"
        @click="add"
        >新增卡片类型</el-button
      >
      <el-button
        type="primary"
        :size="$publicConfig.buttonSize"
        icon="el-icon-download"
        @click="download"
        >导出教师专属卡片</el-button
      >
    </template>
    <div class="cards_main">
      <el-row
        v-if="cards.length > 0"
        :gutter="33"
        style="width: calc(100% + 16px)"
      >
        <el-col
          :span="6"
          v-for="(o, k) in cards"
          :key="k"
          style="margin-bottom: 24px"
        >
          <el-card :body-style="{ padding: '0px' }">
            <img :src="o.coverImage" class="image" @click="proViewImage(o.coverImage)"/>
            <div class="card_text_main">
              <span>{{ o.name }}</span>
              <div class="bottom">
                <i
                  class="el-icon-edit"
                  style="margin-right: 16px"
                  @click="addEdit(o)"
                ></i>
                <i class="el-icon-delete" @click="deleteEdit(o)"></i>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div v-else class="null_main">
        <img
          width="352"
          height="243"
          src="@/assets/images/card_null.png"
          alt=""
        />
        <span>暂未添加卡片</span>
      </div>
    </div>
    <add ref="add" @close="getCardConfigList" :row="addRow"></add>
    <download ref="download" @close="getCardConfigList" :cards="cards"></download>
    <el-image-viewer
        v-if="showViewer"
        :on-close="close"
        :url-list="[viewImage]"
      />
  </award-card>
</template>

<script>
import Add from "./components/add";
import Download from "./components/download.vue";
import { getCardConfigList, deleteCardConfig } from "@/http/awardCard";
import { mapGetters } from "vuex";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  data() {
    return {
      cards: [],
      addRow: {},
      viewImage: '',
      showViewer: false
    };
  },
  created() {
    this.getCardConfigList();
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  components: {
    Add,
    Download,
    ElImageViewer
  },
  methods: {
    proViewImage(url) {
      this.viewImage = url
      this.showViewer = true
    },
    close() {
      this.showViewer = false
    },
    add() {
      this.addRow = {};
      this.$refs.add.show();
    },
    addEdit(v) {
      this.addRow = v;
      this.$refs.add.show();
    },
    deleteEdit(v) {
      this.$confirm("删除后无法恢复, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteCardConfig(this.userInfo.tnId, v.id).then(res => {
            if(res.retCode == 1) {
              this.$message.success("删除成功");
              this.getCardConfigList()
            }else {
              this.$message.error(res.retMsg)
            }
          })
        })
        .catch(() => {
        });
    },
    download() {
      this.$refs.download.show();
    },
    getCardConfigList() {
      getCardConfigList(this.userInfo.tnId).then((res) => {
        if (res.retCode == 1) {
          this.cards = res.data;
        }
      });
    },
  },
};
</script>
<style lang='scss'>
.el-icon-circle-close {
  font-size: 32px;
  color: $main-color;
}
</style>
<style lang='scss' scoped>
.cards_main {
  margin-top: 24px;
  height: 100%;
  overflow-y: auto;
  .null_main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      margin-top: 12px;
    }
  }
  .image {
    width: 100%;
    height: 138px;
    cursor:pointer;
  }
  .card_text_main {
    height: 53px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
    }
    .bottom {
      color: #bbc5d7;
      cursor: pointer;
      i {
        font-size: 20px;
      }
    }
  }
}
</style>