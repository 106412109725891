<template>
  <el-dialog
    title="导出教师专属卡片"
    :visible.sync="dialogFormVisible"
    width="902px"
    :close-on-press-escape="false"
    @close="dialogClose"
  >
    <el-form :model="form" label-width="100px" ref="form">
      <el-form-item label="卡片信息：">
        <el-checkbox v-model="checked">卡片类型</el-checkbox>
        <span class="type_tip">默认包含教师姓名</span>
        <div class="tips">
          注：如未选择卡片类型，学生在扫码收集时将需要手动选择获得的奖卡类型
        </div>
      </el-form-item>
      <el-form-item label="卡片类型：" v-if="checked">
        <el-checkbox-group v-model="types" class="card_types">
          <el-checkbox :label="v.name" v-for="(v, k) in cards" :key="k">
            <div>
              <img
                class="images"
                :src="v.coverImage"
                width="130"
                height="73"
                alt=""
              />
              <div class="label">
                {{ v.name }}
              </div>
            </div>
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="卡片样例：">
        <div class="card_surface">
          <div class="surface">
            <img
              @click="
                proViewImage(
                  'https://91chhenzhi.oss-cn-hangzhou.aliyuncs.com/mini/awardcard1.png'
                )
              "
              class="images"
              src="https://91chhenzhi.oss-cn-hangzhou.aliyuncs.com/mini/awardcard1.png"
              width="195"
              height="110"
              alt=""
            />
            <div class="tip">正面</div>
          </div>
          <div class="surface">
            <img
              v-if="checked"
              @click="
                proViewImage(
                  'https://91chhenzhi.oss-cn-hangzhou.aliyuncs.com/mini/awardcard8.png'
                )
              "
              class="images"
              src="https://91chhenzhi.oss-cn-hangzhou.aliyuncs.com/mini/awardcard8.png"
              width="195"
              height="110"
              alt=""
            />
            <img
              v-else
              class="images"
              @click="
                proViewImage(
                  'https://91chhenzhi.oss-cn-hangzhou.aliyuncs.com/mini/awardcard7.png'
                )
              "
              src="https://91chhenzhi.oss-cn-hangzhou.aliyuncs.com/mini/awardcard7.png"
              width="195"
              height="110"
              alt=""
            />
            <div class="tip">反面</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="教师卡片数：" style="margin-top: 14px">
        <div class="upload_main" >
          <excel
            :formatter="formatter"
            @changeFile="changeFile"
            id="excel"
            class="upload-demo"
          >
            <el-button size="small" type="primary">{{ tips }}</el-button>
            <div class="el-upload__tip">
              注：可上传每位老师分别需要的卡片数量
            </div>
          </excel>
          <export-excel
            :tableData="exportData"
            ref="exportExcel"
            :header="headerData"
          >
            <el-link
              class="tips"
              :underline="false"
              type="success"
              @click="exportExcel"
              >点击下载模版</el-link
            >
          </export-excel>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button
        @click="
          () => {
            dialogFormVisible = false;
            dialogClose();
          }
        "
        >取 消</el-button
      >
      <el-button type="primary" @click="submit" style="width: auto">下载图片包</el-button>
    </div>
    <div class="records_main" @click="getAllTask">导出记录</div>
    <el-image-viewer
      v-if="showViewer"
      :on-close="close"
      :url-list="[viewImage]"
    />
    <el-dialog
      width="900px"
      title="导出记录"
      :visible.sync="innerVisible"
      append-to-body
    >
      <div class="records_tips">
        注：删除该批次卡片后，教师卡片总数将对应减少，如已打印卡片，学生集卡时将无法识别该批卡片
      </div>
      <el-table :data="tableData" stripe style="width: 100%" height="300">
        <el-table-column prop="createDate" label="导出时间" width="160">
          <template slot-scope="scope">
            {{ $cftY(scope.row.createDate) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="导出教师数" width="120">
          <template slot-scope="scope">
            {{ scope.row.teacherAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="卡片类型">
          <template slot-scope="scope">
            {{ Object.keys(scope.row.detailJson[0].cardCounts).join("/") }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="卡片数量" width="120">
        </el-table-column>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-button type="text" @click="deleteRow(scope.row)" size="small">
              删除该批次
            </el-button>
            <el-button
              v-if="scope.row.status == 'FILE_READY'"
              type="text"
              size="small"
            >
              <el-link
                style="font-size: 12px"
                type="primary"
                :href="scope.row.downloadUrl"
                :underline="false"
                >下载</el-link
              >
            </el-button>

            <el-button v-else type="text" size="small" disabled>
              生成中
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Excel from "./excel";
import ExportExcel from "./exportExcel";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import {
  generateCardData,
  createdTask,
  getAllTask,
  getTaskDetail,
  deleteTask,
} from "@/http/awardCard";

export default {
  data() {
    return {
      form: {
        taskId: "",
      },
      tips: "上传模板",
      viewImage: "",
      showViewer: false,
      types: [],
      dialogFormVisible: false,
      checked: false,
      innerVisible: false,
      tableData: [],
      exportData: [{}],
      headerData: [],
      loading: null,
      //定时任务获取单条提示
      interval: null,
      //当前存储的任务id
      currentTaskId: 0,
    };
  },
  props: {
    cards: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    Excel,
    ExportExcel,
    ElImageViewer,
  },
  created() {
    // this.getAllTask()
  },
  computed: {
    ...mapGetters(["userInfo"]),
    formatter() {
      if (this.checked) {
        return ["teacherName", ...this.types];
      } else {
        return ["teacherName", "totalCount"];
      }
    },
  },
  methods: {
    getAllTask() {
      getAllTask(this.userInfo.tnId).then((res) => {
        this.tableData = res.data;
        this.innerVisible = true;
      });
    },
    dialogClose() {
      this.form = {};
      this.checked = false;
      this.types = [];
      this.dialogFormVisible = false;
    },
    proViewImage(url) {
      this.viewImage = url;
      this.showViewer = true;
    },
    close() {
      this.showViewer = false;
    },
    exportExcel() {
      this.loading = this.$loading({
        lock: true,
        text: "导出中，请稍后",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      }); 
      let arr = [];
      if (this.checked) {
        if (this.types.length == 0) {
          this.loading.close();
          this.$message.error("请选择卡片类型");
          return;
        }
        arr = this.types.map((item) => {
          return item + "数量";
        });
      } else {
        arr = ["卡片数量"];
      }
      console.log("1");
      this.headerData = ["教师姓名", ...arr];
      setTimeout(() => {
        this.$refs.exportExcel.export();
        this.loading.close();
        this.$message.success('导出成功')
      }, 500);
    },
    changeFile(data) {
      let items = [];
      if (data.header.length != this.formatter.length) {
        this.$message.error("上传内容与所选类型不匹配, 请重新上传");
        return;
      }
      data.data.forEach((i) => {
        let item = { cardCounts: {} },
          total = 0;
        item.teacherName = i.teacherName;
        if (this.checked) {
          this.types.forEach((type) => {
            total += i[type];
            item.cardCounts[type] = i[type];
          });
          item.totalCount = total;
        } else {
          item.cardCounts = {};
          item.totalCount = i.totalCount;
        }
        items.push(item);
      });
      this.tips = "已上传，点击替换";
      this.form.items = items;
    },
    show() {
      this.dialogFormVisible = true;
    },
    submit() {
      this.loading = this.$loading({
        lock: true,
        text: "任务创建中，请稍后",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.form.schoolId = this.userInfo.tnId;
      createdTask(this.form).then((res) => {
        if (res.retCode == 1) {
          this.currentTaskId = res.data.id;
          generateCardData(res.data.id).then((res1) => {
            this.loading.close();
            if (res1.retCode == 1) {
              this.tips = "上传模板";
              this.form.items = [];
              this.dialogClose();
              this.$alert(
                "图片正在生成中，请勿刷新或者关闭页面，生成完成后可在导出记录查看",
                "系统提示",
                {
                  confirmButtonText: "确定",
                  callback: () => {
                    this.interval = setInterval(() => {
                      this.getTaskDetail();
                    }, 5000);
                  },
                }
              );
            } else {
              this.$message.error(res1.retMsg);
            }
          });
        } else {
          this.loading.close();
          this.$message.error(res.retMsg);
        }
      });
    },
    getTaskDetail() {
      getTaskDetail(this.currentTaskId).then((res) => {
        if (res.data.status == "FILE_READY") {
          this.$message.success("图片已生成，请在导出记录中下载");
          clearInterval(this.interval);
        }
      });
    },
    // downloadFile(row) {
    //   this.loading = this.$loading({
    //     lock: true,
    //     text: '下载中，请稍后',
    //     spinner: 'el-icon-loading',
    //     background: 'rgba(0, 0, 0, 0.7)'
    //   });
    //   downloadFile(row.id).then(res => {
    //     this.loading.close()
    //     if(res.retCode == 1) {
    //       this.$message.success('下载完成')
    //     }else {
    //       this.$message.error('下载出错')
    //     }
    //   })
    // },
    deleteRow(row) {
      this.$confirm("删除后无法恢复, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteTask(this.userInfo.tnId, row.id).then((res) => {
            if (res.retCode == 1) {
              this.getAllTask()
              this.$message.success("删除成功");
            } else {
              this.$message.error(res.retMsg);
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.card_types {
  .el-checkbox__input {
    position: absolute;
    right: -10px;
    bottom: 25px;
  }
}
</style>
<style scoped lang="scss">
.records_tips {
  height: 40px;
  background: #f7f8fa;
  border-radius: 2px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #9da3ba;
  text-align: center;
  margin-bottom: 24px;
}
.tips {
  color: #999999;
  margin: -12px 0 12px 0;
}
.type_tip {
  color: #00c9d9;
  margin-left: 24px;
}
.records_main {
  position: absolute;
  right: 71px;
  top: 20px;
  color: #00c9d9;
  text-decoration: underline;
}
.card_types {
  width: auto;
  overflow-x: auto;
  display: flex;
  margin-bottom: 20px;
  .el-checkbox {
    position: relative;
    width: 130px;
    height: 100px;
    border-radius: 4px;
    margin: 8px 24px 8px 0;
    .images {
      border-radius: 2px;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.19);
    }
    .label {
      width: 100%;
      text-align: center;
      margin-top: 8px;
    }
  }
}
.card_surface {
  display: flex;
  .surface {
    width: 243px;
    height: 190px;
    background: #f4f5fa;
    padding: 18px 24px;
    text-align: center;
    cursor: pointer;
    margin-right: 24px;
    .tip {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      margin-top: 12px;
    }
  }
}
.upload_main {
  position: relative;
  .tips {
    position: absolute;
    left: 140px;
    top: 10px;
    color: #00c9d9;
    text-decoration: underline;
  }
}
</style>